import {
  faCloudMeatball,
  faPersonSkating,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowUpFromGroundWater } from "@fortawesome/free-solid-svg-icons/faArrowUpFromGroundWater";
import { faArrowUpFromWaterPump } from "@fortawesome/free-solid-svg-icons/faArrowUpFromWaterPump";
import { faArrowUpRightDots } from "@fortawesome/free-solid-svg-icons/faArrowUpRightDots";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons/faBoltLightning";
import { faBomb } from "@fortawesome/free-solid-svg-icons/faBomb";
import { faBurst } from "@fortawesome/free-solid-svg-icons/faBurst";
import { faCloudBolt } from "@fortawesome/free-solid-svg-icons/faCloudBolt";
import { faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons/faCloudShowersHeavy";
import { faCloudShowersWater } from "@fortawesome/free-solid-svg-icons/faCloudShowersWater";
import { faFire } from "@fortawesome/free-solid-svg-icons/faFire";
import { faFireExtinguisher } from "@fortawesome/free-solid-svg-icons/faFireExtinguisher";
import { faHandcuffs } from "@fortawesome/free-solid-svg-icons/faHandcuffs";
import { faHillAvalanche } from "@fortawesome/free-solid-svg-icons/faHillAvalanche";
import { faHillRockslide } from "@fortawesome/free-solid-svg-icons/faHillRockslide";
import { faHouseCrack } from "@fortawesome/free-solid-svg-icons/faHouseCrack";
import { faHouseFloodWater } from "@fortawesome/free-solid-svg-icons/faHouseFloodWater";
import { faHouseFloodWaterCircleArrowRight } from "@fortawesome/free-solid-svg-icons/faHouseFloodWaterCircleArrowRight";
import { faHouseTsunami } from "@fortawesome/free-solid-svg-icons/faHouseTsunami";
import { faHurricane } from "@fortawesome/free-solid-svg-icons/faHurricane";
import { faIcicles } from "@fortawesome/free-solid-svg-icons/faIcicles";
import { faIndustry } from "@fortawesome/free-solid-svg-icons/faIndustry";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faMound } from "@fortawesome/free-solid-svg-icons/faMound";
import { faOilWell } from "@fortawesome/free-solid-svg-icons/faOilWell";
import { faPersonRifle } from "@fortawesome/free-solid-svg-icons/faPersonRifle";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faRadiation } from "@fortawesome/free-solid-svg-icons/faRadiation";
import { faRoad } from "@fortawesome/free-solid-svg-icons/faRoad";
import { faSmog } from "@fortawesome/free-solid-svg-icons/faSmog";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons/faSnowflake";
import { faSunPlantWilt } from "@fortawesome/free-solid-svg-icons/faSunPlantWilt";
import { faTemperatureArrowDown } from "@fortawesome/free-solid-svg-icons/faTemperatureArrowDown";
import { faTemperatureArrowUp } from "@fortawesome/free-solid-svg-icons/faTemperatureArrowUp";
import { faTentArrowDownToLine } from "@fortawesome/free-solid-svg-icons/faTentArrowDownToLine";
import { faTornado } from "@fortawesome/free-solid-svg-icons/faTornado";
import { faTrain } from "@fortawesome/free-solid-svg-icons/faTrain";
import { faUmbrella } from "@fortawesome/free-solid-svg-icons/faUmbrella";
import { faVolcano } from "@fortawesome/free-solid-svg-icons/faVolcano";
import { faWater } from "@fortawesome/free-solid-svg-icons/faWater";
import { faWind } from "@fortawesome/free-solid-svg-icons/faWind";

import { SubHazardType as HapiSubHazardType } from "@/apis/services/HapiService";
import {
  RiskRatingHazardsEnum,
  SubHazardType,
  HazardType,
} from "@/apis/services/HazardService";

export const HAZARD_ICONS: any = {
  // Hazard Types
  [HazardType.FLOOD]: faWater,
  [HazardType.SEISMIC]: faHouseCrack,
  [HazardType.WILDFIRE]: faFire,
  [HazardType.WIND]: faWind,
  [HazardType.TORNADO]: faTornado,
  [HazardType.ADJACENCY]: faBurst,
  [HazardType.ACCIDENTAL]: faBurst,
  [HazardType.TSUNAMI]: faHouseTsunami,
  [HazardType.LANDSLIDE]: faHillRockslide,
  [HazardType.VOLCANO]: faVolcano,
  [HazardType.AVALANCHE]: faHillAvalanche,
  [HazardType.DROUGHT]: faSunPlantWilt,
  [HazardType.HEAT]: faTemperatureArrowUp,
  [HazardType.COLD]: faTemperatureArrowDown,
  [HazardType.HAIL]: faCloudMeatball,
  [HazardType.DUST_STORM]: faBurst,
  [HazardType.SNOW]: faSnowflake,
  [HapiSubHazardType.SNOW_PRESSURE]: faSnowflake,
  [HazardType.ICE_STORM]: faIcicles,
  [HapiSubHazardType.ICE_THICKNESS]: faPersonSkating,
  [HapiSubHazardType.PRECIPITATION]: faUmbrella,
  [HazardType.THUNDERSTORM]: faCloudBolt,
  [HazardType.INTENTIONAL]: faHandcuffs,

  // Subhazard types
  [SubHazardType.INTEGRATED_FLOODING]: faWater,
  [SubHazardType.EARTHQUAKE_SHAKING]: faHouseCrack,
  [SubHazardType.RIVERINE_FLOODING]: faHouseFloodWater,
  [SubHazardType.COASTAL_FLOODING]: faHouseFloodWaterCircleArrowRight,
  [SubHazardType.STORMWATER_FLOODING]: faCloudShowersHeavy,
  [SubHazardType.GROUNDWATER_FLOODING]: faArrowUpFromGroundWater,
  [SubHazardType.INTEGRATED_WIND]: faWind,
  [SubHazardType.CYCLONIC_WIND]: faHurricane,
  // [SubHazardType.TORNADO]: faTornado, // already defined above
  // [SubHazardType.WILDFIRE]: faFire, // already defined above
  // [SubHazardType.TSUNAMI]: faFire, // already defined above
  // [SubHazardType.LANDSLIDE]: faFire, // already defined above
  // [SubHazardType.VOLCANO]: faFire, // already defined above
  // [SubHazardType.AVALANCHE]: faFire, // already defined above
  // [SubHazardType.DROUGHT]: faFire, // already defined above
  // [SubHazardType.HEAT]: faFire, // already defined above
  // [SubHazardType.COLD]: faFire, // already defined above
  // [SubHazardType.HAIL]: faFire, // already defined above
  // [SubHazardType.DUST_STORM]: faFire, // already defined above
  // [SubHazardType.SNOW]: faFire, // already defined above
  // [SubHazardType.ICE_STORM]: faFire, // already defined above
  // [SubHazardType.THUNDERSTORM]: faFire, // already defined above
  [SubHazardType.HIGHWAY]: faRoad,
  [SubHazardType.RAILWAY]: faTrain,
  [SubHazardType.AIRPORT]: faPlane,
  [SubHazardType.NUCLEAR]: faRadiation,
  [SubHazardType.PIPELINE]: faOilWell,
  [SubHazardType.LIGHTNING]: faBoltLightning,
  [SubHazardType.LIQUEFACTION]: faArrowUpFromWaterPump,
  [SubHazardType.GROUND_SETTLEMENT]: faTentArrowDownToLine,
  [SubHazardType.DAMS]: faMound,
  [SubHazardType.WILDFIRE_SMOKE]: faSmog,
  [SubHazardType.INDUSTRIAL_FACILITY]: faIndustry,
  [SubHazardType.TERRORISM]: faBomb,
  [SubHazardType.CRIMINALITY]: faHandcuffs,
  [SubHazardType.STATE_ON_STATE_MILITARY_ATTACK]: faPersonRifle,
  [SubHazardType.ROAD_LPG_BLEVE]: faRoad,
  [SubHazardType.ROAD_FIRE]: faRoad,
  [SubHazardType.ROAD_TOXIC_GAS_RELEASE]: faRoad,
  [SubHazardType.ROAD_DERAILMENT]: faRoad,
  [SubHazardType.RAIL_LPG_BLEVE]: faTrain,
  [SubHazardType.RAIL_FIRE]: faTrain,
  [SubHazardType.RAIL_TOXIC_GAS_RELEASE]: faTrain,
  [SubHazardType.RAIL_DERAILMENT]: faTrain,
  [SubHazardType.AIRPORT_ADJACENCY]: faPlane,

  // Riskrating types
  [RiskRatingHazardsEnum.GeophysicalSeismic]: faHouseCrack,
  [RiskRatingHazardsEnum.GeophysicalLiquefaction]: faArrowUpFromWaterPump,
  [RiskRatingHazardsEnum.GeophysicalTsunami]: faHouseTsunami,
  [RiskRatingHazardsEnum.GeophysicalLandslide]: faHillRockslide,
  [RiskRatingHazardsEnum.GeophysicalVolcano]: faVolcano,
  [RiskRatingHazardsEnum.GeophysicalAvalanche]: faHillAvalanche,
  [RiskRatingHazardsEnum.GeophysicalGroundSettlement]: faTentArrowDownToLine,

  [RiskRatingHazardsEnum.HydrologicalFlooding]: faWater,
  [RiskRatingHazardsEnum.HydrologicalStormwaterFlooding]: faCloudShowersHeavy,
  [RiskRatingHazardsEnum.HydrologicalRiverineFlooding]: faHouseFloodWater,
  [RiskRatingHazardsEnum.HydrologicalCoastalFloodingAndSeaLevelRise]:
    faHouseFloodWaterCircleArrowRight,
  [RiskRatingHazardsEnum.HydrologicalGroundwaterFlooding]:
    faArrowUpFromGroundWater,

  [RiskRatingHazardsEnum.ClimatologicalExtremeHeat]: faTemperatureArrowUp,
  [RiskRatingHazardsEnum.ClimatologicalDrought]: faSunPlantWilt,
  [RiskRatingHazardsEnum.ClimatologicalWildfire]: faFire,
  [RiskRatingHazardsEnum.ClimatologicalWildfireSmoke]: faSmog,
  [RiskRatingHazardsEnum.ClimatologicalExtremeCold]: faTemperatureArrowDown,

  [RiskRatingHazardsEnum.MeteorologicalIntegratedWind]: faWind,
  [RiskRatingHazardsEnum.MeteorologicalCyclonicWind]: faHurricane,
  [RiskRatingHazardsEnum.MeteorologicalThunderstormsLocalStorms]: faCloudBolt,
  [RiskRatingHazardsEnum.MeteorologicalExtraTropicalDepressions]:
    faCloudShowersWater,
  [RiskRatingHazardsEnum.MeteorologicalTornado]: faTornado,
  [RiskRatingHazardsEnum.MeteorologicalDustStorm]: faBurst,
  [RiskRatingHazardsEnum.MeteorologicalHail]: faCloudMeatball,
  [RiskRatingHazardsEnum.MeteorologicalSnow]: faSnowflake,
  [RiskRatingHazardsEnum.MeteorologicalIceStorm]: faIcicles,
  [RiskRatingHazardsEnum.MeteorologicalLightning]: faBoltLightning,

  [RiskRatingHazardsEnum.AdjacencyAirport]: faPlane,
  [RiskRatingHazardsEnum.AdjacencyHighway]: faRoad,
  [RiskRatingHazardsEnum.AdjacencyRailway]: faTrain,
  [RiskRatingHazardsEnum.AdjacencyPipeline]: faOilWell,
  [RiskRatingHazardsEnum.AdjacencyIndustrialFacilities]: faIndustry,
  [RiskRatingHazardsEnum.AdjacencyNuclear]: faRadiation,
  [RiskRatingHazardsEnum.AdjacencyDams]: faMound,
  [RiskRatingHazardsEnum.AdjacencyRoads]: faRoad,

  [RiskRatingHazardsEnum.TechnologicalStructuralIntegrity]: faLink,
  [RiskRatingHazardsEnum.TechnologicalFire]: faFireExtinguisher,

  [RiskRatingHazardsEnum.IntentionalTerrorism]: faBomb,
  [RiskRatingHazardsEnum.IntentionalCriminality]: faHandcuffs,
  [RiskRatingHazardsEnum.IntentionalStateOnStateMilitaryAttack]: faPersonRifle,

  aggregate: faArrowUpRightDots,
};
