import type {
  ClimateScenario,
  RiskClassEnum,
  SubHazardType,
} from "@/apis/services/HazardService";

type OPTIONS_TYPE =
  | "total"
  | "subHazardType"
  | "risk_class"
  | "climate_scenario"
  | "time_horizon";

export const OPTIONS_TYPE_LOOKUP: { [key: string]: string } = {
  subHazardType: "subHazardType",
  risk_class: "riskClass",
  climate_scenario: "climateScenario",
  time_horizon: "timeHorizon",
};

export type OpenSearchOptions = {
  type?: OPTIONS_TYPE;
  subHazardType?: SubHazardType | "all";
  riskClass?: RiskClassEnum | "all";
  climateScenario?: ClimateScenario | "all";
  timeHorizon?: number | "all";
};

const OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: "subhazard_type.keyword",
  consequence: "consequence_type.keyword",
  riskClass: "risk_class",
  climateScenario: "climate_scenario.keyword",
  rcpScenario: "rcp_scenario.keyword",
  timeHorizon: "time_horizon",
};

export const RISK_RATING_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: `${OPEN_SEARCH_KEYS.subHazardType}`,
  consequence: `${OPEN_SEARCH_KEYS.consequence}`,
  rcpScenario: `${OPEN_SEARCH_KEYS.rcpScenario}`,
  timeHorizon: `${OPEN_SEARCH_KEYS.timeHorizon}`,
};

export const ASSESSMENT_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardType: `assessment.${OPEN_SEARCH_KEYS.subHazardType}`,
  riskClass: `assessment.${OPEN_SEARCH_KEYS.riskClass}`,
  climateScenario: `assessment.${OPEN_SEARCH_KEYS.climateScenario}`,
  timeHorizon: `assessment.${OPEN_SEARCH_KEYS.timeHorizon}`,
};
