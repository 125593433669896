import type { HTTPValidationError } from "@/apis/services/AssetService";
import { ContentType, HttpClient } from "@/apis/services/AssetService";

export interface OpenSearchResponse {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: null;
    hits: any[];
  };
  aggregations: {
    [key: string]: {
      doc_count_error_upper_bound: number;
      sum_other_doc_count: number;
      buckets: { key: string; doc_count: number }[];
    };
  };
}

export interface OpenSearchRequest {
  size: number;
  query?: {
    bool?: {
      filter: any[];
    };
    match_all?: any;
  };
  aggs?: {
    [x: string]: {
      terms: {
        field: string;
        size: number;
      };
    };
  };
}

export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  assessment = {
    getData: (data: OpenSearchRequest, orgId: string) =>
      this.request<OpenSearchResponse, HTTPValidationError>({
        path: `/${orgId}-assessment/_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
      }),
  };
  risk_rating = {
    getData: (data: OpenSearchRequest, orgId: string) =>
      this.request<OpenSearchResponse, HTTPValidationError>({
        path: `/${orgId}-risk-rating/_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
      }),
  };
}
