import type { OpenSearchRequest } from "@/apis/services/OpenSearchService";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useServices } from "@/hooks/useServices";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

interface Options {
  searchKey?: string;
  field?: string;
  filter?: any[];
}

export const useOpenSearch = (options: Options) => {
  const { searchKey, field, filter = [] } = options;
  const { data: id } = useUserInfo();
  const { openSearch } = useServices();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const isEnabled = isFlagEnabled(flags, "useOpenSearch");

  const body: OpenSearchRequest = { size: 0 };
  if (filter?.length === 0) {
    body["query"] = {
      match_all: {},
    };
  }

  if (filter?.length > 0) {
    body["query"] = {
      bool: {
        filter: filter,
      },
    };
  }

  if (searchKey && field) {
    body["aggs"] = {
      [searchKey as string]: {
        terms: {
          field: field,
          size: 1000,
        },
      },
    };
  }

  return { openSearch, isEnabled, orgId, body };
};
